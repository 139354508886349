<template>
  <v-container fluid>
    <div>
      <!-- le workflow applicatif -->
      <div class="d-flex justify-center" flat tile>
        <Workflow
          width="600"
          height="180"
          :steps="workflowSteps"
          :currentStep="workflowIndex"
          :labelWidth="200"
          :lineWidth="140"
        ></Workflow>
      </div>

      <v-row justify="center">
        <v-col xl="6" lg="8" md="8" sm="10" xs="12">
          <!-- le titre et le bouton retour -->
          <div class="d-flex justify-center">
            <TitleAndReturnComponent title="Application" />
          </div>

          <div class="d-flex justify-center">
            <WarningEditComponent />
          </div>

          <!-- la barre de progression -->
          <v-row justify="center" no-gutters>
            <v-col xl="6" lg="8" md="8" sm="10" xs="12">
              <!-- la progess bar à afficher lors du chargement des données -->
              <v-progress-linear
                indeterminate
                :active="loading"
              ></v-progress-linear>
            </v-col>
          </v-row>

          <!-- l'affichage de la table -->
          <v-card flat outlined class="my-4">
            <v-card-title class="font-weight-regular">
              <v-row no-gutters justify="space-between">
                <div>Détail d'une application</div>
              </v-row>
            </v-card-title>

            <!-- le label -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">label</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le label"
                    dense
                    v-model="label"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- le nom digital -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    nom digital
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Le nom digital"
                    dense
                    v-model="digitalName"
                    @blur="digitalNameFieldOut"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- le mots clés -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    les mots clés
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-text-field
                    placeholder="Les mots clés"
                    dense
                    v-model="keywords"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- is groupe -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">
                    app collaborateur
                  </div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox 
                  on-icon="mdi-checkbox-outline"
                  off-icon="mdi-checkbox-blank-outline"
                  v-model="isGroup"></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <!-- le service -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">service</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-autocomplete
                    placeholder="Sélectionner un service"
                    :items="services"
                    item-text="name"
                    item-value="id"
                    no-data-text="aucun service"
                    v-model="serviceId"
                    clearable
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- La categorie -->
            <!-- <InputFieldComponent label="catégorie">
              <template v-slot:value>
                <v-text-field
                  placeholder="Catégorie"
                  dense
                  v-model="serviceCategory"
                ></v-text-field>
              </template>
            </InputFieldComponent> -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">catégorie</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-combobox
                    placeholder="Sélectionner ou saisir une catégorie"
                    :items="availableCategory"
                    item-text="category"
                    item-value="category"
                    no-data-text="aucune catégorie"
                    v-model="serviceCategory"
                    clearable
                  >
                  </v-combobox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <InputFieldComponent label="ordre dans la catégorie">
              <template v-slot:value>
                <v-text-field
                  placeholder="Ordre dans la catégorie"
                  dense
                  v-model="ranking"
                  type="number"
                  :rules="[rules.positive]"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">nouveauté</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox 
                    on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="isnew"></v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>


            <InputFieldComponent label="url">
              <template v-slot:value>
                <v-text-field
                  placeholder="Url de l'application"
                  dense
                  v-model="url"
                  :rules="[rules.validate]"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <!-- statut actif ou non de l'application -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">actif</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox 
                    on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="isactive">
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <!-- l'application s'ouvre dans un nouvel onglet ? -->
            <v-card-text>
              <v-row no-gutters align="center">
                <v-col cols="5" justify="center" align-self="center">
                  <div class="capitalize font-weight-regular">ouvrir dans un nouvel onglet</div>
                </v-col>
                <v-col cols="7" align-self="center">
                  <v-checkbox 
                    on-icon="mdi-checkbox-outline"
                    off-icon="mdi-checkbox-blank-outline"
                    v-model="openinnewtab">
                  </v-checkbox>
                </v-col>
              </v-row>
              <v-divider></v-divider>
            </v-card-text>

            <InputFieldComponent label="nom de l'icône">
              <template v-slot:value>
                <v-text-field
                  placeholder="Nom de l'icône"
                  dense
                  v-model="iconName"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <InputFieldComponent label="ordre dans le menu" :divider="false">
              <template v-slot:value>
                <v-text-field
                  placeholder="Ordre dans le menu"
                  dense
                  v-model="menuRanking"
                  type="number"
                  :rules="[rules.positive]"
                ></v-text-field>
              </template>
            </InputFieldComponent>

            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                outlined
                class="ma-2 px-4 btn"
                color="primary"
                :disabled="!hasChanged"
                @click="save"
                >
                <div class="capitalize">
                  enregistrer
                </div>
              </v-btn
              >
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </div>

    <AlertNotSavedModifsComponent
      :show="showAlertQuit"
      @quit="onQuitAlert"
      @notquit="onNotQuitAlert"
    />

    <!-- afficher des messages -->
    <v-snackbar
      v-model="snackbarVisible"
      :color="snackbarColor"
      :timeout="snackbarTimeout"
      :left="snackbarLeft"
      :right="snackbarRight"
      :top="snackbarTop"
      :bottom="snackbarBottom"
      >{{ snackbarMessage }}</v-snackbar
    >
  </v-container>
</template>

<script>
import Workflow from "@/components/Workflow.vue";
import WorkflowMixin from "@/components/mixins/WorkflowMixin.js";

import RoleMixin from "@/components/mixins/RoleMixin.js";
import TableViewEditWorkflowMixin from "@/components/mixins/TableViewEditWorkflowMixin.js";
import SnackBarMixin from "@/components/mixins/SnackBarMixin.js";

import AlertNotSavedModifsMixin from "@/components/mixins/AlertNotSavedModifsMixin.js";
import AlertNotSavedModifsComponent from "@/components/ui/AlertNotSavedModifsComponent.vue";
import InputFieldComponent from "@/components/ui/InputFieldComponent.vue";

import TitleAndReturnComponent from "@/components/ui/TitleAndReturnComponent.vue";
import WarningEditComponent from "@/components/ui/WarningEditComponent.vue";

import { ApplicationService } from "@/service/dictionary/applications_service.js";
import ServicesService from "@/service/sfr/services_service.js";

import { areTheSame, trimString } from "@/tools/string_tool.js";
import { checkUrl } from "@/tools/url_tool.js";

import * as exceptions from "@/service/exception_to_message.js";

export default {
  name: "EditApplication",
  components: {
    Workflow,
    TitleAndReturnComponent,
    WarningEditComponent,
    AlertNotSavedModifsComponent,
    InputFieldComponent,
  },

  mixins: [
    WorkflowMixin,
    RoleMixin,
    TableViewEditWorkflowMixin,
    SnackBarMixin,
    AlertNotSavedModifsMixin,
  ],
  data() {
    return {
      /**en cours de chargement. */
      loading: false,

      /**le service d'accès au APIs. */
      service: null,

      /**le service d'accès aux services. */
      serviceServices: null,

      /** l'identifiant à éditer */
      entityId: null,

      /**La donnée originale */
      source: null,

      /** le label */
      label: null,

      /** le nom digital */
      digitalName: null,

      /** les mots clés */
      keywords: null,

      /** est-ce une app du groupe */
      isGroup: null,

      /**identifiant du service associé  */
      serviceId: null,

      /**l'ordre d'affichage dans la catégorie */
      ranking: null,

      /**est une nouveauté */
      isnew: null,

      /**l'url de l'application */
      url: null,

      /**application activée dans le lanceur */
      isactive: null,

      /** l'application doit elle être ouverte dans un nouvel onglet */
      openinnewtab: null,

      /** la catégorie de service */
      serviceCategory: null,

      /** le nom de l'icône de l'application */
      iconName: null,

      /** le numéro d'ordre dans le menu */
      menuRanking: null,

      /**la liste des services */
      services: [],

      /** la liste des categories */
      categories: [],

      rules: {
        validate: (value) =>
          checkUrl(value) || "veuillez saisir une url valide",
        positive: (value) => value >= 0 || "pas de valeur négative",
      },
    };
  },
  methods: {
    async load() {
      try {
        this.loading = true;

        let entity = await this.service.getById(this.entityId);
        this.source = JSON.parse(JSON.stringify(entity));

        this.services = await this.serviceServices.getAllServices();

        // Récupère toutes les applications
        let apps = await this.service.getAll();
        // Parcours les applications 
        for (let app of apps) {          
          let foundCat = this.categories.find((c) => c.category == app.serviceCategory && c.serviceId == app.serviceId);

          if (!foundCat) {
            this.categories.push({
              serviceId: app.serviceId,
              category: app.serviceCategory,
            });
          }
        }

        this.init();
      } catch (error) {
        console.error(error);
        this.addErrorToSnackbar(
          "chargement des données: " +
            (exceptions.toMessage(error) || "problème technique")
        );
      } finally {
        this.loading = false;
      }
    },
    /**initialiser les données à partir de l'objet source */
    init() {
      this.label = this.source.label;
      this.digitalName = this.source.digitalName;
      this.keywords = this.source.keywords;
      this.isGroup = this.source.isgroup;

      if (this.source.serviceId) {
        this.serviceId = this.source.serviceId;
      }

      this.ranking = this.source.ranking;

      if (this.source.url) {
        this.url = this.source.url;
      }

      this.isnew = this.source.isnew;
      this.isactive = this.source.isactive;
      this.openinnewtab = this.source.openinnewtab;
      this.serviceCategory = this.source.serviceCategory;
      this.iconName = this.source.iconName;
      this.menuRanking = this.source.menuRanking;

    },

    async save() {
      // enregistrement de l'entity
      if (this.entityHasChanged) {
        try {
          let entity = JSON.parse(JSON.stringify(this.source));
          entity.label = this.label;
          entity.digitalName = this.digitalName;
          entity.keywords = this.keywords;
          entity.isgroup = this.isGroup;

          entity.serviceId = this.serviceId;
          entity.ranking = this.ranking;
          entity.isnew = this.isnew;
          entity.url = this.url;
          entity.isactive = this.isactive;
          entity.openinnewtab = this.openinnewtab;
          entity.iconName = this.iconName;
          entity.menuRanking = this.menuRanking;
          entity.serviceCategory = this.serviceCategory;

          await this.service.update(entity);

          this.disableAlertQuit();
          this.$router.go(-1);
        } catch (error) {
          console.error(error);
          this.addErrorToSnackbar(
            "enregistrement des données de l'application : " +
              (exceptions.toMessage(error) || "problème technique")
          );
        }
      }
    },

    /** Méthode appelée lors de l'évènement de sortie de champ nom digital */
    digitalNameFieldOut() {
      this.digitalName = trimString(this.digitalName);
    },
  },
  computed: {
    completed() {
      if (!this.label) {
        return false;
      }

      if (!this.digitalName) {
        return false;
      }

      // vérification de la présence du service retirée le 14/01/2021 par SR
      // if (!this.serviceId) {
      //   return false;
      // }

      if (this.url) {
        if (!checkUrl(this.url)) {
          return false;
        }
      }

      if (this.ranking) {
        if (this.ranking < 0) {
          return false;
        }
      }
      if (this.menuRanking) {
        if (this.menuRanking < 0) {
          return false;
        }
      }

      return true;
    },
    hasChanged() {
      if (!this.source) return false;

      if (!this.completed) return false;

      let changed = false;

      if (this.entityHasChanged) {
        changed = true;
      }

      return changed;
    },
    /**retourne true si l'entité est modifié */
    entityHasChanged() {
      let changed = false;

      if (!areTheSame(this.source.label, this.label)) {
        changed = true;
      }
      if (!areTheSame(this.source.digitalName, this.digitalName.trim())) {
        changed = true;
      }
      if (!areTheSame(this.source.keywords, this.keywords)) {
        changed = true;
      }
      if (this.source.isgroup != this.isGroup) {
        changed = true;
      }

      if (this.source.serviceId != this.serviceId) {
        changed = true;
      }

      if (!areTheSame(this.serviceCategory, this.source.serviceCategory)) {
        changed = true;
      }

      if (this.source.ranking != this.ranking) {
        changed = true;
      }

      if (this.source.isnew != this.isnew) {
        changed = true;
      }

      if (this.source.url != this.url) {
        changed = true;
      }

      if (this.source.isactive != this.isactive) {
        changed = true;
      }

      if (this.source.openinnewtab != this.openinnewtab) {
        changed = true;
      }

      if (!areTheSame(this.source.iconName, this.iconName)) {
        changed = true;
      }

      if (this.source.menuRanking != this.menuRanking) {
        changed = true;
      }

      return changed;
    },

    /** Met à disposition les catégories  */
    availableCategory() {
      let items = [];

      if (this.serviceId) {
        let itemsCat = this.categories.filter((c) => c.serviceId == this.serviceId && c.category != null);
        items = itemsCat.map((i) => i.category);
      }

      return items;
    },
  },
  mounted() {
    //instanciation des services d'accès aux APIs
    this.service = new ApplicationService(this.$api.getApplicationApi());

    this.serviceServices = new ServicesService(this.$api);

    // Initialisation du worflow
    this.initTableViewEditWorkflow(this.canEdit, 1);

    //l'identifiant de la route est passé dans l'url
    if (this.$route.params.id) {
      this.entityId = this.$route.params.id;
    }

    this.load();
  },
};
</script>

<style>
</style>